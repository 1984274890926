interface Input {
    label: String,
    loading?: boolean,
    onclick?: any,
}

const BuildButton: React.FC<Input> = (data) => {
    return <>
        <div className="flex justify-end">
            <button disabled={data.loading} onClick={data.onclick} className="bg-primary flex text-white font-semibold rounded px-4 py-2 hover:bg-blue-700">
                {data.loading ? "Traitement" : data.label}
            </button>
        </div>
    </>
}

export default BuildButton;