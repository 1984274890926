// Home.tsx
import React from 'react';
import AppBar from '../widgets/app_bar';
import { Outlet } from 'react-router-dom';

function Home() {
  return (
    <div>
      <AppBar></AppBar>
      <div className='py-10 px-10'>
        <Outlet></Outlet>
      </div>
      {/* Votre contenu de la page d'accueil ici */}
    </div>
  );
};

export default Home;