import { FieldValues, UseFormRegister, useForm } from "react-hook-form";

interface Input {
    register?: any,
    label: String,
    errors?: any,
    defaultValue?: any,
    type?: any,
}


const FormInput: React.FC<Input> = (data) => {
    return (
        <>
            <div className="mb-4 w-full mr-1 ml-1">
                <label className="block text-gray-700 text-sm font-bold mb-2">{data.label}</label>
                <input {...data.register}
                    defaultValue={data.defaultValue}
                    id="inputField"
                    type={data.type ?? "text"}
                    className={`w-full px-3 py-1 border-none bg-white rounded-md focus:outline-none focus:ring focus:border-blue-300 ${data.errors ? "border-danger" : ""} `}
                    placeholder={data.defaultValue}
                    disabled={data.defaultValue != null}
                />
            </div>
        </>
    )
}

export default FormInput;