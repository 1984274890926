import React, { useEffect, useState } from "react";
import { post } from "../../httpService";
import { convertToTitleCase, formatDateTime } from "../../utils/util_function";
import { ArrowTopRightOnSquareIcon, ChatBubbleBottomCenterIcon, PencilSquareIcon, PhoneArrowDownLeftIcon, TrashIcon } from "@heroicons/react/24/outline";
import get from "../../httpService";
import FormInput from "../../widgets/form_input";
import BuildButton from "../../widgets/button_input";
import { SubmitHandler, useForm } from "react-hook-form";

type FormData = {
    id: number,
    designation: string,
    quantity: number,
    unit_price: number,
}

const BookingList: React.FC = () => {

    const [bookingList, setBookingList] = useState<any>([]);
    const [isLoad, setIsLoad] = useState<boolean>(true);
    const [selectedItem, setSelectedItem] = useState<any>([]);
    const [invoiceData, setInvoiceData] = useState<any>([]);
    const [invoiceIsLoad, setInvoiceIsLoad] = useState<boolean>(false);
    const [conversationIsLoad, setConversationIsLoad] = useState<boolean>(true);

    //shops form
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm<FormData>()

    const onSubmit: SubmitHandler<FormData> = (data) => {

        var postData = {
            "designation": data.designation,
            "qte": data.quantity,
            "prix": data.unit_price,
            "total": (data.unit_price * data.quantity)
        };
        reset();
        setInvoiceData((invoiceData: any) => [...invoiceData, postData]);
    }

    const removeItem = (index: any) => {
        const temp = [...invoiceData];
        temp.splice(index, 1);
        setInvoiceData(temp);
    };

    const createConversation = (userId: any) => {

        var postData = {
            'receiver_id': userId,
            'sender_id': 1,
            'message': 'Bonjour',
        }

        post('/sendChat', postData)
            .then((response) => {
                console.log('Réponse GET :', response.data);
                setInvoiceIsLoad(false);
            })
            .catch((error) => {
                console.error('Erreur GET :', error);
                setInvoiceIsLoad(false);
            });

    }

    useEffect(() => {
        getbooking();
    }, []);

    const getbooking = (data?: any) => {
        get('/backend/booking/list', data)
            .then((response) => {
                console.log('Réponse GET :', response.data);
                setBookingList(response.data['data']);
                setIsLoad(false);
            })
            .catch((error) => {
                console.error('Erreur GET :', error);
            });
    }

    const sendInvoice = () => {

        if (selectedItem.length == 0) {
            alert('Veuillez selectionner la commande');
            return;
        }

        setInvoiceIsLoad(true);

        const sumTotal = invoiceData.reduce((total: any, item: any) => total + item.total, 0);

        var postData = {
            montant: sumTotal,
            tva: 0,
            description: selectedItem.services.description,
            idDemande: selectedItem.id,
            receiver_id: selectedItem.user.id,
            lignecmd: invoiceData.length > 0 ? JSON.stringify(invoiceData) : null,
            sender_id: 1,
            message: "Votre facture",
        };

        console.log(postData);

        post('/backend/facture', postData)
            .then((response) => {
                console.log('Réponse GET :', response.data);
                setInvoiceIsLoad(false);
                setSelectedItem([]);
                setInvoiceData([]);
            })
            .catch((error) => {
                console.error('Erreur GET :', error);
                setInvoiceIsLoad(false);
            });

    }

    return (<>

        <div className="card border p-5 mb-5 rounded border-grey bg-white">
            <div className="flex items-center justify-between">
                <div>
                    <h1>Accueil / Commandes / Liste</h1>
                    <h1 className="text-lg">Liste des Commandes</h1>
                </div>
            </div>
        </div>
        <div className="flex flex-row bg-grey10 px-5 py-5 mb-5">
            <div className="w-2/3">
                <h4 className="font-bold">Facturation</h4>
                <p className="mb-0">Num Commande : {selectedItem?.id}</p>
                <p className="mb-0">Address de livraison : {selectedItem?.city}</p>
                <hr className="my-4" />
                <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
                    <div className="flex items-center justify-between">
                        <FormInput label="Désignation" register={register("designation", { required: true })} errors={errors.designation}></FormInput>
                        <FormInput label="Quantité" type="number" register={register("quantity", { required: true })} errors={errors.quantity}></FormInput>
                        <FormInput label="Prix unitaire" type="number" register={register("unit_price", { required: true })} errors={errors.unit_price}></FormInput>
                    </div>

                    <div className="text-left">
                        <BuildButton label={"Ajouter"} ></BuildButton>
                    </div>

                </form>
            </div>

            <div className="w-2/3 ml-10">
                {selectedItem != null &&
                    <>
                        <div className="flex items-center justify-between">
                            <div>
                                <p>Client.e : {selectedItem.user?.nom} {selectedItem.user?.prenom}</p>
                                <p>Téléphone : {selectedItem.user?.tel}</p>
                                <p>Service : {selectedItem.services?.nom_service}</p>
                            </div>
                            <div className="cursor-pointer bg-primary rounded-full p-2 text-white hover:text-danger" onClick={() => createConversation(selectedItem.user?.id)}>
                                <ChatBubbleBottomCenterIcon width={30}></ChatBubbleBottomCenterIcon>
                            </div>
                        </div>
                        <hr className="my-4" />
                    </>
                }
                {invoiceData.length > 0 &&
                    <>
                        <table className="my-5 table-auto w-full">
                            <thead>
                                <tr className="text-sm text-left">
                                    <th>ID</th>
                                    <th>Designation</th>
                                    <th>Quantité</th>
                                    <th>Prix Unitaire</th>
                                    <th>Prix Total</th>
                                    <th>Option</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {invoiceData.map((invoice: any, index: number) => (
                                    <tr key={index} className="text-left">
                                        <td>{index}</td>
                                        <td>{invoice.designation}</td>
                                        <td>{invoice.qte}</td>
                                        <td>{invoice.prix}</td>
                                        <td>{invoice.total}</td>
                                        <td> <TrashIcon width={20} className="mr-5 text-danger" onClick={() => {
                                            removeItem(index);
                                        }} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <p className="text-end mb-4 font-bold text-lg">Total: {invoiceData.reduce((total: any, item: any) => total + item.total, 0)}FCFA</p>
                        <hr className="my-4" />
                        <div className="text-left">
                            <BuildButton label={"Facturer"} loading={invoiceIsLoad} onclick={() => sendInvoice()}  ></BuildButton>
                        </div>
                    </>
                }

            </div>

        </div>

        <table className="table-auto w-full">
            <thead>
                <tr className='text-left border border-grey text-sm text-bold bg-grey10'>
                    <th className='text-center'>N°</th>
                    <th className='text-center'>ID</th>
                    <th>Service</th>
                    <th>Client</th>
                    <th>Télphone</th>
                    <th>Ville</th>
                    <th>Quartier</th>
                    <th>Besion</th>
                    <th>Date solicitation</th>
                    <th>Heure</th>
                    <th>Status</th>
                    <th>Options</th>
                </tr>
            </thead>
            <tbody className="bg-white">
                {!isLoad && <>
                    {bookingList.data.length > 0 && bookingList.data.map((booking: any, index: any) =>
                        <React.Fragment key={booking.id}>
                            <tr className={`border border-grey text-sm px-4 ${selectedItem.id == booking.id ? "text-primary" : ""} `}>
                                <td className='text-center'>{index}</td>
                                <td className='text-center'>{booking.id}</td>
                                <td>{booking.services.nom_service}</td>
                                <td>{convertToTitleCase(booking.user.nom)} {convertToTitleCase(booking.user.prenom)}</td>
                                <td>{booking.tel}</td>
                                <td>{booking.user.ville != null ? convertToTitleCase(booking.user.ville) : ""}</td>
                                <td>{booking.city}</td>
                                <td>{booking.description}</td>
                                <td>{formatDateTime(booking.date)}</td>
                                <td>{booking.heure}</td>
                                <td><span className={`badge ${booking.status === 4 ? "text-white bg-yellow" : booking.status === 0 ? "bg-grey" : booking.status === 1 ? "bg-primary text-white" : booking.status === 2 ? "bg-danger text-white" : booking.status === 3 ? "bg-green" : ""}`} >{booking.status === 0 ? "Attente" : booking.status === 1 ? "En cours" : booking.status === 2 ? "Rejetée" : booking.status === 3 ? "terminée" : "Paiement"}</span></td>
                                <td>
                                    <div className="flex cursor-pointer">
                                        <ArrowTopRightOnSquareIcon width={20} color="green" className="mr-5" onClick={() => {
                                            setSelectedItem(booking);
                                        }} />
                                    </div>
                                </td>
                            </tr>

                        </React.Fragment>
                    )}
                </>}

                {isLoad && <>
                    <tr><td colSpan={9} className="text-center"></td></tr>
                    <tr><td colSpan={9} className="text-center">Loading...</td></tr>
                    <tr><td colSpan={9} className="text-center"></td></tr>
                </>}

            </tbody>
        </table>
    </>)
}

export default BookingList;