import React, { useEffect, useState } from "react";
import { post } from "../../httpService";
import { convertToTitleCase, formatDateTime } from "../../utils/util_function";
import { ArrowTopRightOnSquareIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import get from "../../httpService";

const UsersList: React.FC = () => {

    const [userList, setUserList] = useState<any>([]);
    const [isLoad, setIsLoad] = useState<boolean>(true);
    const [selectedItem, setSelectedItem] = useState<any>([]);

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = (data?: any) => {
        get('/backend/user', data)
            .then((response) => {
                console.log('Réponse GET :', response.data);
                setUserList(response.data['data']);
                setIsLoad(false);
            })
            .catch((error) => {
                console.error('Erreur GET :', error);
            });
    }

    return (<>

        <div className="card border p-5 mb-5 rounded border-grey bg-white">
            <div className="flex items-center justify-between">
                <div>
                    <h1>Accueil / Utilisateurs / Liste</h1>
                    <h1 className="text-lg">Liste des utilisateurs</h1>
                </div>
            </div>
        </div>
        <table className="table-auto w-full">
            <thead>
                <tr className='text-left border border-grey text-sm text-bold bg-grey10'>
                    <th className='text-center'>N°</th>
                    <th>Date inscription</th>
                    <th className='text-center'>ID</th>
                    <th>Nom</th>
                    <th>Prenom</th>
                    <th> Téléphone</th>
                    <th>Statut</th>
                    <th>Ville</th>
                    <th>Options</th>
                </tr>
            </thead>
            <tbody className="bg-white">
                {!isLoad && <>
                    {userList?.data.length > 0 && userList?.data.map((users: any, index: any) =>
                        <React.Fragment key={users.id}>
                            <tr className={`border border-grey text-sm px-4 ${selectedItem.id == users.id ? "text-primary" : ""} `}>
                                <td className='text-center'>{index}</td>
                                <td>{formatDateTime(users.created_at)}</td>
                                <td className='text-center'>{users.id}</td>
                                <td>{convertToTitleCase(users.nom)}</td>
                                <td>{convertToTitleCase(users.prenom)}</td>
                                <td>{users.tel}</td>
                                <td className={`text-white text-center ${users.is_verify == 1 ? "bg-green-500" : "bg-red-600"}`}>{users.is_verify == 1 ? "Verifié" : "Non verifié"}</td>
                                <td>{users.ville != null ? convertToTitleCase(users.ville) : ""}</td>
                                <td>
                                    <div className="flex cursor-pointer">
                                        <PencilSquareIcon width={20} color="green" className="mr-5" onClick={() => {

                                        }} />
                                        <ArrowTopRightOnSquareIcon width={20} color="green" className="mr-5" onClick={() => {

                                        }} />
                                    </div>
                                </td>
                            </tr>

                            

                        </React.Fragment>
                    )}
                </>}

                {isLoad && <>
                    <tr><td colSpan={9} className="text-center"></td></tr>
                    <tr><td colSpan={9} className="text-center">Loading...</td></tr>
                    <tr><td colSpan={9} className="text-center"></td></tr>
                </>}

            </tbody>
        </table>
    </>)
}

export default UsersList;