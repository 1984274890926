import { format, Locale } from "date-fns";
import frLocale from "date-fns/locale/fr";

export function formatDateTime(dateTimeString: string): string {
    const originalDate = new Date(dateTimeString);
    const formattedDate = format(originalDate, 'dd MMMM yyyy', { locale: frLocale });
    return formattedDate;
}

export function buildAvatar(url: any) {
    return <>
        {url != null && <img src={url} alt="•" className="h-10 w-10 rounded-full border-2 border-grey" />}
        {url == null && <div className="relative w-10 h-10 overflow-hidden rounded-full border-2 border-grey">
            <svg className="absolute w-12 h-12 text-white -left-1 bg-gray" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
        </div>}

    </>
}


export function convertToTitleCase(text: any) {
    return text
        .toLowerCase()
        .split(' ')
        .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}


