import { BuildingStorefrontIcon, CircleStackIcon } from "@heroicons/react/24/outline";
import { post } from "../httpService";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

type filterForm = {
    start_date: string
    end_date: string
}


const Statistiques: React.FC = () => {
    const [statData, setStatData] = useState<any>([]);
    const [isLoad, setIsLoad] = useState<any>([]);

    const getAllStats = (data?:any) => {
        post('/statistics/index/', data)
            .then((response) => {
                console.log('Réponse GET :', response.data);
                setStatData(response.data['data']);
                setIsLoad(false);
            })
            .catch((error) => {
                console.error('Erreur GET :', error);
            });
    }

    // Search Form
    const {
        register: register3,
        handleSubmit: handleSubmit3,
        setValue: setValue3,
        reset: reset3,
        formState: { errors: errors3 },
    } = useForm<filterForm>()



    const onSubmit3: SubmitHandler<filterForm> = (data) => {
        getAllStats(data);
        console.log(data);
    }

    useEffect(() => {
        getAllStats();
    }, []);


    return <div>
        <div className="card border p-5 mb-5 rounded border-grey bg-white">
            <div className="flex items-center justify-between">
                <div>
                    <h1>Accueil / Statistiques</h1>
                    <h1 className="text-lg">Tous les statistiques</h1>
                </div>
                <div className="bg-white flex items-center justify-between p-4">
                    <form onSubmit={handleSubmit3(onSubmit3)}>
                        <div className="date bg-white">
                            Du <input type="date" className="rounded-full mr-4 ml-4"
                                {...register3('start_date')} />
                            Au <input type="date" className="rounded-full ml-4"
                                {...register3('end_date')} />

                            <button className="ml-2" >Rechercher</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        {isLoad ?
            <div className="flex items-center w-full justify-center bg-white p-10">Calcul en cours...</div> :

            <div className="flex">
                <div className="shadow-sm bg-white w-1/3 p-5">
                    <div className="flex items-center">
                        <CircleStackIcon width={30} className='mr-2' />
                        <p> Stock (Magasin)</p>
                    </div>
                    <hr className="mt-3 mb-5" />
                    <p className="my-5">Montant Stock : {statData.cout_achat_magasin} FCFA</p>
                    <p>Estimation vente : {statData.cout_vente_magasin} FCFA</p>
                    <p className="my-5">Estimation Gain : {statData.cout_vente_magasin - statData.cout_achat_magasin} FCFA</p>
                </div>
                <div className="shadow-sm bg-white w-1/3 ml-2 mr-2 p-5">
                    <div className="flex items-center">
                        <BuildingStorefrontIcon width={30} className='mr-2' />
                        <p> Stock Boutiques</p>
                    </div>
                    <hr className="mt-3 mb-3" />
                    {statData.stat_stock_shop.map((shop: any) =>
                        <>
                            <div key={shop.id}>
                                <p className="text-primary font-bold mb-2">Boutique : {shop.shop_name}</p>
                                <p>Montant Stock: {shop.cout_achat_shop} FCFA</p>
                                <p>Estimation Vente Min: {shop.cout_vente_shop} FCFA</p>
                                <p>Estimation Gain Min: {shop.cout_vente_shop - shop.cout_achat_shop} FCFA</p>
                            </div>
                            <hr className="mb-4 mt-4" />
                        </>
                    )}

                </div>
                <div className="shadow-sm bg-white w-1/3 ml-2 mr-2 p-5">
                    <div className="flex items-center">
                        <BuildingStorefrontIcon width={30} className='mr-2' />
                        <p>Stats Ventes</p>
                    </div>
                    <hr className="mt-3 mb-3" />
                    {statData.stat_sale_shop.length > 0 ?
                        statData.stat_sale_shop.map((shop: any) =>
                            <>
                                <div key={shop.id}>
                                    <p className="text-primary font-bold mb-2">Boutique : {shop.shop_name}</p>
                                    <p>Montant Achat: {shop.cout_achat_sale} FCFA</p>
                                    <p>Montant Vente: {shop.cout_vente_sale} FCFA</p>
                                    <p>Gain: {shop.cout_vente_sale - shop.cout_achat_sale} FCFA</p>
                                </div>
                                <hr className="mb-4 mt-4" />
                            </>
                        ) : <>
                            <p>Aucune donnée pour cette date</p>
                        </>}
                </div>
            </div>
        }
    </div>
}

export default Statistiques;