import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from './components/home';
import Login from './components/login';
import { useEffect, useState } from 'react';
import Statistiques from './components/statistique';
import ShopSettings from './components/add_shop';
import { ToastContainer } from 'react-toastify';
import UsersList from './components/users/user_list';
import BookingList from './components/booking/booking_list';
import Services from './components/services/service';
import Messaging from './components/messaging/messaging';
import Category from './components/services/category';

function App() {

  const [authData, setAuthData] = useState<any>(null);

  useEffect(() => {
    const saved = localStorage.getItem("authUser");
    const initialValue = saved != null ? JSON.parse(saved) : null;
    setAuthData(initialValue);
  }, []);

  return (
    <>
      <HashRouter>
        <Routes>
          {authData != null &&
            <Route path="/" element={<Home />} >
              <Route path="/" element={<UsersList />} />
              <Route path="users" element={<UsersList />} />
              <Route path="booking" element={<BookingList />} />
              <Route path="services" element={<Services />} />
              <Route path="categories" element={<Category />} />
              <Route path="messaging" element={<Messaging />} />
              <Route path="settings/shop" element={<ShopSettings />} />
              <Route path="statistiques" element={<Statistiques />} />
            </Route>
          }
          {authData == null &&
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Login />} />
              <Route path="/*" element={<Login />} />
            </>
          }
        </Routes>

        <ToastContainer></ToastContainer>
      </HashRouter>
    </>

  );
}

export default App;
