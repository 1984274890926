import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { json } from 'stream/consumers';
import { post } from '../httpService';

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [authData, setAuthData] = useState<any>([]);


    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const postData = {
            "tel": email,
            "password": password,
            "isAdmin": true,
        }
        console.log(postData);

        post('/auth/login', postData)
            .then((response) => {
                console.log('Réponse GET :', response.data);
                if (response.data.success) {
                    localStorage.setItem('authUser', JSON.stringify(response.data.data))
                    window.location.href = "/";
                }else{
                    toast("Nom utilisateur ou mot de passe incorrect");
                }
              
            })
            .catch((error) => {
                console.error('Erreur GET :', error);
                toast("Nom utilisateur ou mot de passe incorrect");
            });

    };



    return (

        <div className="flex justify-center items-center h-screen bg-white">
            <div className="w-1/2">
                <div className="login m-8">
                    <h2 className="text-2xl font-semibold mb-4">Login</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <input
                                type="text"
                                id="email"
                                name="email"
                                placeholder="Utilisateur"
                                value={email}
                                onChange={handleEmailChange}
                                className="w-full mt-1 p-3 border rounded-lg"
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Mot de passe"
                                value={password}
                                onChange={handlePasswordChange}
                                className="w-full mt-1 p-3 border rounded-lg"
                            />
                        </div>
                        <div className="mb-6 mt-6">
                            <button
                                type="submit"
                                className="w-full py-4 px-4 text-white bg-primary rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700"
                            >
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="w-1/2 bg-primary">
                <div className="flex flex-col justify-center items-center h-screen">
                    {/* <img src="logo.png" height={100} alt="" /> */}
                    <h1 className='text-white text-bold text-5xl'>Tafadom</h1>
                </div>
            </div>
        </div>

    );
};

export default Login;
