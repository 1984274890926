
import { useEffect, useState } from "react";
import React from "react";
import { PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useForm, SubmitHandler } from "react-hook-form"
import { ArrowUpCircleIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { post, get } from "../../httpService";
import FormInput from "../../widgets/form_input";
import BuildButton from "../../widgets/button_input";
import { formatDateTime } from "../../utils/util_function";

enum FormView {
    none = "none",
    update = "update",
}


type FormData = {
    id: number,
    name: string,
    description: string,
    image: string,
}

const Services: React.FC = () => {
    const [servicesData, setservicesData] = useState<any>([]);
    const [isLoad, setIsLoad] = useState<boolean>(true);
    const [addIsLoad, setAddIsLoad] = useState<boolean>(false);
    const [updateForm, setUpdateShowForm] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [formviewMode, setFormviewMode] = useState<FormView>();

    //shops form
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm<FormData>()



    const onSubmit: SubmitHandler<FormData> = (data) => {
        console.log(data);
        setAddIsLoad(true);
        if (updateForm) {
            updateShop(data);
            return;
        }
        addShop(data);
    }




    const addShop = (data: any) => {
        post('/shop/add', data).then((response) => {
            console.log('Réponse GET :', response.data);
            toast(response.data.message);
            setAddIsLoad(false);
            reset();
            getServices();
        }).catch((error) => {
            setAddIsLoad(false);
            console.error('Erreur GET :', error);
        });
    }


    const getServices = (data?: any) => {
        get('/backend/service', data)
            .then((response) => {
                console.log('Réponse GET :', response.data);
                setservicesData(response.data['data']);
                setIsLoad(false);
            })
            .catch((error) => {
                console.error('Erreur GET :', error);
            });
    }





    const deleteShop = (id?: number) => {
        setSelectedItem(null);
        post('/shop/delete/', { 'id': id })
            .then((response) => {
                console.log('Réponse GET :', response.data);
                toast(response.data.message);
                getServices();
                setIsLoad(false);
                reset();
            })
            .catch((error) => {
                console.error('Erreur GET :', error);
                reset();
            });
    }


    const updateShop = (shop: any) => {

        setAddIsLoad(true);

        post('/shop/update', shop)
            .then((response) => {
                console.log('Réponse GET :', response.data);
                toast(response.data.message);
                getServices();
                setAddIsLoad(false);
            })
            .catch((error) => {
                console.error('Erreur GET :', error);
                setAddIsLoad(false);
            });
    }

    const initshop = (shop: any) => {
        setSelectedItem(shop.id);
        setUpdateShowForm(true);
        setValue('id', shop.id);
        setValue("name", shop.name);
        setValue("description", shop.description);
        setValue('image', "");
    }

    const search = (key?: String) => {
        var data = {
            'key': key,
        }
        getServices(data);
    }

    useEffect(() => {
        getServices();
    }, []);


    return (
        <div>

            <div className="card border p-5 mb-5 rounded border-grey bg-white">
                <div className="flex items-center justify-between">
                    <div>
                        <h1>Paramettre / Services</h1>
                        <h1 className="text-lg">Liste des services</h1>
                    </div>
                    <div>
                        <button className="bg-primary flex items-center text-white font-semibold rounded px-4 py-2 hover:bg-blue-700" onClick={() => {
                            setFormviewMode(formviewMode == FormView.update ? FormView.none : FormView.update);
                            setUpdateShowForm(false);
                            reset();
                        }} >
                            {formviewMode == FormView.update ? <ArrowUpCircleIcon width={23} className="mr-2" /> : <PlusIcon width={23} className="mr-2" />} Nouveau service
                        </button>
                    </div>
                </div>
            </div>
            {formviewMode == FormView.update && <div className="form-zone bg-grey10 px-5 py-5 mb-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex items-center justify-between">
                        <FormInput label="Nom" register={register("name", { required: true })} errors={errors.name}></FormInput>
                        <FormInput label="Indication" register={register("description", { required: true })} errors={errors.description} ></FormInput>
                    </div>
                    <div className="flex justify-between">
                        {selectedItem != null ? <div className="flex items-center text-danger cursor-pointer" onClick={() => deleteShop(selectedItem)}><TrashIcon width={20} className="cursor-pointer" color="red" />Supprimer</div> : <div></div>}
                        <div className="flex items-center">
                            <BuildButton label={updateForm ? "Modifier" : "Ajouter"} loading={addIsLoad} ></BuildButton>
                        </div>
                    </div>
                </form>
            </div>
            }

            {/* Votre contenu de la page d'accueil ici */}
            <div className="bg-white flex items-center justify-between p-4">
                <div className="search">
                    <input type="search" name="search" placeholder="Rechercher" className="rounded-full"
                        onChange={(even: any) => {
                            search(even.target.value);
                        }}
                    />
                </div>
            </div>
            <table className="table-auto w-full">
                <thead>
                    <tr className='text-left border border-grey text-sm text-bold bg-grey10'>
                        <th className='text-center'>N°</th>
                        <th>Icon</th>
                        <th>Banière</th>
                        <th>Nom</th>
                        <th>Description</th>
                        <th>Prix min</th>
                        <th>Categorie</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {!isLoad && <>
                        {servicesData.data.length > 0 && servicesData.data.map((services: any, index: any) =>
                            <React.Fragment key={services.id}>
                                <tr className={`border border-grey text-sm px-4 ${selectedItem == services.id ? "text-primary" : ""} `}>
                                    <td className='text-center'>{index}</td>
                                    <td><img src={services.img} alt="img" height={50} width={50} /></td>
                                    <td><img src={services.banner} alt="img" height={50} width={50} /></td>
                                    <td>{services.nom_service}</td>
                                    <td>{services.description}</td>
                                    <td>{services.montant_min}FCFA</td>
                                    <td>{services.category.nom_category}</td>
                                    <td>
                                        <div className="flex cursor-pointer">
                                            <PencilSquareIcon width={20} color="green" className="mr-5" onClick={() => {
                                                initshop(services);
                                                setFormviewMode(FormView.update);
                                            }} />
                                        </div>
                                    </td>
                                </tr>

                            </React.Fragment>
                        )}
                    </>}

                    {isLoad && <>
                        <tr><td colSpan={9} className="text-center"></td></tr>
                        <tr><td colSpan={9} className="text-center">Loading...</td></tr>
                        <tr><td colSpan={9} className="text-center"></td></tr>
                    </>}

                </tbody>
            </table>
        </div>
    );
}

export default Services;