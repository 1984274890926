import axios from 'axios';

const baseURL = 'https://api.taf-adom.com/public/api/v1/';
//const baseURL = "http://localhost:8000/api/v1/";
const httpService = axios.create({
    baseURL,
    // timeout: 10000, // Temps d'attente maximal en millisecondes
});

// Ajouter un intercepteur avant chaque requête
httpService.interceptors.request.use((config) => {
    // Récupérer l'objet authUser de l'endroit où vous le stockez (par exemple, localStorage)
    const authUserString = localStorage.getItem('authUser');
    
    // Convertir la chaîne en objet s'il n'est pas nul
    const authUser = authUserString ? JSON.parse(authUserString) : null;
  
    // Si un token est disponible dans l'objet authUser, l'ajouter à l'en-tête Authorization
    if (authUser && authUser.access_token) {
      config.headers.Authorization = `Bearer ${authUser.access_token}`;
    }
  
    return config;
  });

// Fonction pour effectuer une requête GET
export const get = (url: any, params = {}) => {
    return httpService.get(url, { params });
};

// Fonction pour effectuer une requête POST
export const post = (url: any, data: any) => {
    return httpService.post(url, data);
};

// Fonction pour effectuer une requête PUT
export const put = (url: any, data: any) => {
    return httpService.put(url, data);
};

// Exportez le service HTTP
export default httpService;
