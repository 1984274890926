import { useEffect, useState } from "react";
import { get, post } from "../../httpService";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { convertToTitleCase, formatDateTime } from "../../utils/util_function";
import { SubmitHandler, useForm } from "react-hook-form";

type FormData = {
    message: string,
}

const Messaging: React.FC = () => {

    const [messageData, setMessageData] = useState<any>([]);
    const [isLoad, setIsLoad] = useState<boolean>(true);
    const [currentchatData, setCurrentchatData] = useState<any>([]);
    const ADMIND_ID = 1;

    //shops form
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm<FormData>()

    const setConversarion = (data: any) => {
        setCurrentchatData(data);
    }

    const onSubmit: SubmitHandler<FormData> = (data) => {
        console.log(data);
        sendMessage(data.message);
        reset();
    }

    const getMessaging = (data?: any) => {
        get('/backend/chat', data)
            .then((response) => {
                console.log('Réponse GET :', response.data);
                setMessageData(response.data['data']);
                setIsLoad(false);
            })
            .catch((error) => {
                console.error('Erreur GET :', error);
            });
    }


    const sendMessage = (message: any) => {

        var postData = {
            'receiver_id': currentchatData.creator?.id != ADMIND_ID ? currentchatData.creator.id : currentchatData.participant.id,
            'sender_id': ADMIND_ID,
            'message': message,
        }

        post('/sendChat', postData)
            .then((response) => {
                console.log('Réponse GET :', response.data);
                getMessaging();

            })
            .catch((error) => {
                console.error('Erreur GET :', error);
            });

    }

    function convertirJsonEnObjet(jsonString: string): { prix: number; designation: string; qte: number; total: number }[] {
        try {
            const tableauDeCommandes = JSON.parse(jsonString);

            if (Array.isArray(tableauDeCommandes)) {
                return tableauDeCommandes.map((commande: any) => ({
                    prix: commande.prix || 0,
                    designation: commande.designation || '',
                    qte: commande.qte || 0,
                    total: commande.total || 0,
                }));
            } else {
                console.error('La chaîne JSON n\'a pas pu être analysée correctement.');
                return [];
            }
        } catch (erreur) {
            console.error('Erreur lors de l\'analyse JSON :', erreur);
            return [];
        }
    }

    useEffect(() => {
        getMessaging();
    }, [])

    return <>
        <div className="col col-md-2 h-chat-screen flex flex-col">
            {isLoad && <p>Loading...</p>}
            {!isLoad && (
                <div className="flex-1 grid grid-rows-2 grid-flow-col gap-0 overflow-hidden h-full">
                    {/* Colonne des utilisateurs à gauche avec overflow-y-auto */}
                    <div className="overflow-y-auto bg-gray-100 rounded row-span-3 p-4">
                        {messageData.data.length > 0 && messageData.data.map((messaging: any, index: any) => (
                            <>
                                <div key={index} className={` ${messaging.id == currentchatData?.id ? "bg-gray-200" : ""} flex items-center space-x-4  my-0 cursor-pointer p-3 rounded-lg hover:bg-gray-300`} onClick={() => setConversarion(messaging)}>
                                    <div className="bg-gray-300 w-12 h-12 flex items-center justify-center rounded-full">
                                        <span className="text-gray-600 font-semibold"> {messaging.creator.id != ADMIND_ID ? convertToTitleCase(messaging.creator.nom[0] + '' + messaging.creator.prenom[0]) : convertToTitleCase(messaging.participant.nom[0] + '' + messaging.participant.prenom[0])}</span>
                                    </div>
                                    <div>
                                        <p className="text-md font-semibold">{messaging.creator.id != ADMIND_ID ? convertToTitleCase(messaging.creator.nom) : convertToTitleCase(messaging.participant.nom)}</p>
                                        <span className="text-sm text-gray-500">{messaging.creator.id != ADMIND_ID ? messaging.creator.tel : messaging.participant.tel}</span>
                                    </div>
                                </div>
                                <hr />
                            </>
                        ))}

                    </div>
                    {/* Colonne de la conversation à droite avec overflow-y-auto */}
                    <div className="col-span-8 row-span-2">
                        {currentchatData.chats == null &&
                            <div className="h-full p-4 pb-20 bg-gray-200 justify-center flex items-center flex-col">
                                <p className="text-lg text-primary">Démarez une conversation</p>
                                <span className="text-sm">Sélectionnez un utilisateur pour commencer la discussion.</span>
                            </div>
                        }
                        <div className="p-4 bg-gray-100 rounded flex text-primary"> <ChatBubbleLeftEllipsisIcon width={25} className="text-primary mr-2" /> {currentchatData.creator?.id != ADMIND_ID ? convertToTitleCase(currentchatData.creator?.nom + ' ' + currentchatData.creator?.prenom) : convertToTitleCase(currentchatData.participant?.nom + ' ' + currentchatData.participant?.prenom)}</div>
                        <div className="overflow-y-auto h-full p-4 pb-20 pr-8">
                            {currentchatData.chats?.length > 0 && currentchatData.chats.map((chatData: any, index: any) => (
                                <div key={currentchatData.chats.id} className="mb-4">
                                    <div className={`${chatData.sender_id === ADMIND_ID ? "text-end" : "text-start"}`}>
                                        {
                                            chatData.invoices == null && 
                                            <div className={`rounded-lg max-w-sm bg-gray-200 inline-block p-2 ${chatData.sender_id === ADMIND_ID ? "bg-primary text-white" : "text-start"}`}>
                                                <p>{chatData.message}</p> 
                                                <span className="mt-4 text-xs">{chatData.created_at}</span>
                                            </div>
                                        }
                                        {
                                            chatData.invoices != null && <>
                                                <div className="shadow-sm py-5 px-5 rounded-lg bg-white inline-block">
                                                    <p className={`rounded-lg p-2 ${chatData.sender_id === ADMIND_ID ? "text-primary" : "text-start"}`}>{chatData.message}</p>
                                                    <p className={`rounded-lg pb-2 -mt-2 text-sm ${chatData.sender_id === ADMIND_ID ? "text-primary" : "text-start"}`}>{formatDateTime(chatData.created_at)}</p>
                            
                                                    {convertirJsonEnObjet(chatData.invoices.lignecmd).map((invoice: any) => (
                                                        <p className="p-0">{invoice.designation} | Qt({invoice.qte}) | {invoice.prix}</p>
                                                    ))}
                                                    <hr className="my-2" />
                                                    <p className="font-bold">{chatData.invoices.montant} FCFA</p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Champ de saisie en bas */}
                    {currentchatData.chats != null &&
                        <div className="col-span-8 bg-gray-100 p-2">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input type="text" placeholder="Votre message" {...register("message", { required: true })} className="border w-full rounded-full" />
                            </form>
                        </div>
                    }
                </div>
            )}
        </div>
    </>
}

export default Messaging;